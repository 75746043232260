
  export const accent_color = "#000"
export const INPUT = {
    colorLink: accent_color,
    colorLinkActive: accent_color,
    colorLinkHover: accent_color,
    colorPrimary: accent_color,
    colorPrimaryActive: accent_color,
    colorPrimaryBorder: accent_color,
    colorPrimaryHover: accent_color,
  };
  export const POPOVER = {
    colorLink: accent_color,
    colorLinkActive: accent_color,
    colorLinkHover: accent_color,
    colorPrimary: accent_color,
    colorPrimaryActive: accent_color,
    colorPrimaryBorder: accent_color,
    colorPrimaryHover: accent_color,
  };

